<template>
  <div class="main">
    <div class="header" id="header">
      <div class="header-box flex">
        <svg-icon class="logo" svg-name="logo" />
        <div class="menuList flex">
          <router-link class="menuItem" to="/">首页</router-link>
          <div
            class="menuItem hoverItem"
            @mouseenter="showMenu = true"
            @mouseleave="showMenu = false"
          >
            产品介绍
            <div
              v-if="showMenu"
              class="menu col"
              @mouseenter="showMenu = true"
              @mouseleave="showMenu = false"
            >
              <router-link class="isBtn" to="/supply">客营盈私域</router-link>
              <router-link class="isBtn" to="/crm">微信CRM</router-link>
              <router-link class="isBtn" to="/amazon">亚马逊</router-link>
            </div>
          </div>
          <router-link class="menuItem isBtn" to="/solution">
            解决方案
          </router-link>
          <router-link class="menuItem isBtn" to="/about">关于我们</router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view v-on:contact="showQrcode = !showQrcode" />
      <div class="flotBox flex">
        <div v-if="showQrcode" class="qrcodeBox">
          <img width="134" height="134" src="~@/assets/images/qrcode.png" />
        </div>
        <div class="options col">
          <svg-icon
            @click="showQrcode = !showQrcode"
            class="toChat isBtn"
            svgName="toChat"
          />
          <svg-icon @click="toTop" class="toTop isBtn" svgName="toTop" />
        </div>
      </div>
    </div>
    <div class="footer col">
      <div class="footer-row flex">
        <svg-icon class="logo" svg-name="logo" />
        <div class="menuList flex">
          <div class="menuItem">
            产品介绍
          </div>
          <div class="menuItem">
            解决方案
          </div>
          <div class="menuItem">
            关于我们
          </div>
        </div>
      </div>
      <div class="footer-row flex" style="margin-bottom: 6px">
        <div class="menuItem shallow no-m pl12">
          咨询热线
        </div>
        <div class="menuList shallow flex">
          <div class="menuItem">
            业务系统
          </div>
          <div class="menuItem">
            移动互联
          </div>
          <div class="menuItem">
            公司介绍
          </div>
        </div>
      </div>
      <div class="footer-row flex" style="margin-bottom: 36px">
        <div class="menuItem no-m pl12">
          15622280570
        </div>
        <div class="menuList shallow flex">
          <div class="menuItem">
            移动软件
          </div>
          <div class="menuItem">
            商业应用
          </div>
          <div class="menuItem">
            企业资讯
          </div>
        </div>
      </div>
      <div class="footer-notice">
        © 2019-2021 Ancoris 版权所有
        <a
          href="https://beian.miit.gov.cn/"
          style="color: #fff"
          target="_blank"
          rel="nofollow"
          >粤ICP备2021113319号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "../components/SvgIcon.vue";
export default {
  components: { SvgIcon },
  name: "MainLayout",
  data() {
    return {
      showMenu: false,
      showQrcode: false
    };
  },
  methods: {
    toTop() {
      // eslint-disable-next-line no-undef
      header.scrollIntoView();
    }
  }
};
</script>

<style lang="stylus" scoped>
.main {
  color: red;

  .logo {
    width: 86px;
    height: 64px;
  }

  .menuItem {
    $FontStyle(14px, $white, normal, 22px);
    margin: 0 32px;
  }

  .hoverItem {
    position: relative;
    cursor: pointer;

    .menu {
      opacity: 1;
      position: absolute;
      width: 120px;
      height: 146px;
      background: rgba(255, 255, 255, 1);
      border-radius: 24px;
      z-index: 999;
      padding: 24px;
      box-sizing: border-box;
      justify-content: space-between;
      text-align: center;

      .isBtn {
        $FontStyle(14px, $black65, 500, 22px);

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  .body {
    position: relative;

    .flotBox {
      bottom: 50px;
      right: 50px;
      position: fixed;
      z-index: 999;

      .qrcodeBox {
        text-align: center;
        $Square(160px);
        box-shadow: 0px 4px 20px rgba(4, 71, 171, 0.08);
        border-radius: 24px;
        line-height: 160px;
        margin-right: 16px;
        background: $white;
      }

      .toChat {
        $Square(80px);
        margin-bottom: 16px;
      }

      .toTop {
        background: $white;
        $Square(24px);
        padding: 12px;
        box-shadow: 0px 4px 20px rgba(4, 71, 171, 0.08);
        border-radius: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .header {
    background: rgba(34, 39, 50, 1);
    height: 64px;

    &-box {
      width: 1200px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footer {
    height: 240px;
    background: $black;
    justify-content: flex-end;
    position: relative;

    .shallow {
      opacity: 0.6;
    }

    &-row {
      width: 1200px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      .pl12 {
        padding-left: 12px;
      }
    }

    &-notice {
      text-align: center;
      $FontStyle(14px, $white45, normal, 22px);
      margin-bottom: 22px;
    }
  }
}
</style>
