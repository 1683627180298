<template>
  <div class="home">
    <div class="home-header">
      <div class="home-header-box col">
        <div class="title">
          客营盈私域供应链管理
        </div>
        <div class="tips">
          私域流量运营专家 精细化客户运营和销售管理 <br />
          技术实力支撑 · 满足企业多种需求
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="tips">
        搭建流量新体系 抢占流量获取和变现的下一个新风口
      </div>
      <div class="title">
        行业集成 多平台店铺打通
      </div>

      <div class="home-body-box flex">
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/chess.png" />
            <img class="sm" src="~@/assets/images/building.png" />
          </div>
          <img class="md" src="~@/assets/images/code.png" />
        </div>
        <div class="left col">
          <div class="title">
            提供多平台解决新方案
          </div>
          <div class="comment">
            多平台之间对接复杂 无法进行统一管理？
          </div>
          <div class="content">
            打通有赞等多电商平台，助力商家打造智慧新零售。可支持订单绑定客户绑定后，方便员工在与客户聊天侧边栏中查看到该客户的历史订单记录与会员信息情况，并可通过关联订单为客户发送红包，还可以把商品页面一键发送给客户，帮助商户在企业微信里形成服务闭环，提升复购率。目前淘宝正在打通中。
          </div>
        </div>
      </div>

      <div class="tips">
        抢占流量获取和变现的下一个新风口
      </div>
      <div class="title">
        营销引流 专注用户裂变增长
      </div>

      <div class="home-body-box flex">
        <div class="left col">
          <div class="title">
            专注用户裂变增长
          </div>
          <div class="comment">
            活动推广效果不如意 营销太难？
          </div>
          <div class="content">
            可以在每个渠道都附上带活码的渠道专属二维码比如可以在自己的公众号、朋友圈广告、企业官网以及其他物料上都附上二维码，将前来咨询新老客户都引进到企业微信，这些客户会被随机分配给某个员工，这样既能保证员工作的公平，又能避免单个号当日加人达到上限的情况，打破蓉销困局大幅提升沟通效率。可以为每个二维码设置不同的标签规则，扫码添加企业成员后自动为客户添加标签。支持实时修改标签规则并且标签数据同步至企业微信官方平台。
          </div>
        </div>
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/girl-eng.png" />
            <img class="sm" src="~@/assets/images/computer.png" />
          </div>
          <img class="md" src="~@/assets/images/computer-long.png" />
        </div>
      </div>

      <div class="tips">
        抢占流量获取和变现的下一个新风口
      </div>
      <div class="title">
        渠道活码 实现客户精准化管理
      </div>

      <div class="home-body-box flex">
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/building-sky.png" />
            <img class="sm" src="~@/assets/images/teach.png" />
          </div>
          <img class="md" src="~@/assets/images/read.png" />
        </div>
        <div class="left col">
          <div class="title">
            助力企业精细化运营
          </div>
          <div class="comment">
            客户来源不清楚 推广方式效果不明？
          </div>
          <div class="content">
            建立企业统一标准的文本销售话术，使得员工回复更加专业化。同时，还支持图片、图文、音频、视频、小程序以及文件等多种素材内容，可按产品、按行业、按业务需求或按部门等对内容进行分类存储管理（支持多级分类），查找查看轻松便利，助力企业私域运营，变成运维高手。可配置内容引擎应用页面和客户详情页到聊天侧边栏。并支持一键发送，提高客户服务效率。方便成员查看并完善客户资料、查看互动轨迹、打标签及记录跟进情况。
          </div>
        </div>
      </div>

      <div class="tips">
        抢占流量获取和变现的下一个新风口
      </div>
      <div class="title">
        数据分析 以数据驱动运营
      </div>

      <div class="home-body-box flex">
        <div class="left col">
          <div class="title">
            针对性改进销售策略
          </div>
          <div class="comment">
            运营效果是否达到预期 下一步该如果进行？
          </div>
          <div class="content">
            分析客户特性选择合适的客户进行营销方案是CRM专员的一种必备技能当前可以通过客户属性、标签、订单交易三个维度寻找目标客户，进行精准二次营销。，通过分析销售漏斗的形状、阶段客户数以及各阶段的跟进转化率等对跟进情况进行评估和判断，量化预测可达到的销售成功率，并且在一定程度上反映跟进过程中存在的潜在问题，进而提出很多针对性的销售策略改进建议。帮助企业实现分析、考核成员联系客户情况的需求。
          </div>
        </div>
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/girl-eng.png" />
            <img class="sm" src="~@/assets/images/computer.png" />
          </div>
          <img class="md" src="~@/assets/images/computer-long.png" />
        </div>
      </div>

      <div class="tips">
        抢占流量获取和变现的下一个新风口
      </div>
      <div class="title">
        数据分析 以数据驱动运营
      </div>
      <div class="home-body-brand" style="margin-bottom: 120px">
        <img src="@/assets/images/card-electron.png" />
        <img src="@/assets/images/card-teach.png" />
        <img src="@/assets/images/card-life.png" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Supply"
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/common.styl"
.home
  &-header
    background-image url("../assets/images/bg-supply.png")
</style>
