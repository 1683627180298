<template>
  <div class="home">
    <div class="home-header">
      <div class="home-header-box col">
        <div class="title">
          亚马逊新电商解决方案
        </div>
        <div class="tips">
          全球多个站点快捷访问，根据商家实时需求解决电商推 <br />
          广难等多个难题
        </div>
        <div @click="application()" class="btn">
          查看详情
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="tips">
        我们能帮你降低50%发货成本
      </div>
      <div class="title">
        省去您能想到的发货成本
      </div>

      <div class="home-body-box flex">
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/great.png" />
            <img class="sm" src="~@/assets/images/watch.png" />
          </div>
          <img class="md" src="~@/assets/images/code.png" />
        </div>
        <div class="left col">
          <div class="title">
            帮您降低成本
          </div>
          <div class="comment">
            发货量不足导致成本太高？
          </div>
          <div class="content">
            可以在每个渠道都附上带活码的渠道专属二维码比如可以在自己的公众号、朋友圈广告、企业官网以及其他物料上都附上二维码，将前来咨询新老客户都引进到企业微信，这些客户会被随机分配给某个员工，这样既能保证员工作的公平，又能避免单个号当日加人达到上限的情况，打破蓉销困局大幅提升沟通效率。可以为每个二维码设置不同的标签规则，扫码添加企业成员后自动为客户添加标签。支持实时修改标签规则并且标签数据同步至企业微信官方平台。
          </div>
        </div>
      </div>

      <div class="tips">
        我们能帮你降低各种库存
      </div>
      <div class="title">
        省去您能想到的库存成本
      </div>

      <div class="home-body-box flex">
        <div class="left col">
          <div class="title">
            有效减少库存成本
          </div>
          <div class="comment">
            自有仓库租赁物流成本太高？
          </div>
          <div class="content">
            可以在每个渠道都附上带活码的渠道专属二维码比如可以在自己的公众号、朋友圈广告、企业官网以及其他物料上都附上二维码，将前来咨询新老客户都引进到企业微信，这些客户会被随机分配给某个员工，这样既能保证员工作的公平，又能避免单个号当日加人达到上限的情况，打破蓉销困局大幅提升沟通效率。可以为每个二维码设置不同的标签规则，扫码添加企业成员后自动为客户添加标签。支持实时修改标签规则并且标签数据同步至企业微信官方平台。
          </div>
        </div>
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/girl-eng.png" />
            <img class="sm" src="~@/assets/images/computer.png" />
          </div>
          <img class="md" src="~@/assets/images/computer-long.png" />
        </div>
      </div>

      <div class="tips">
        我们只做正品 同样的价格更具优势
      </div>
      <div class="title">
        更好的品质 最低的价格
      </div>

      <div class="home-body-box flex">
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/great.png" />
            <img class="sm" src="~@/assets/images/watch.png" />
          </div>
          <img class="md" src="~@/assets/images/code.png" />
        </div>
        <div class="left col">
          <div class="title">
            更好的品质保证
          </div>
          <div class="comment">
            礼品质量参差不齐 消费者不满意？
          </div>
          <div class="content">
            建立企业统一标准的文本销售话术，使得员工回复更加专业化。同时，还支持图片、图文、音频、视频、小程序以及文件等多种素材内容，可按产品、按行业、按业务需求或按部门等对内容进行分类存储管理（支持多级分类），查找查看轻松便利，助力企业私域运营，变成运维高手。可配置内容引擎应用页面和客户详情页到聊天侧边栏。并支持一键发送，提高客户服务效率。方便成员查看并完善客户资料、查看互动轨迹、打标签及记录跟进情况。
          </div>
        </div>
      </div>

      <div class="tips">
        销售平台导出数据无需修改可直接下单发货
      </div>
      <div class="title">
        一键发货到平台
      </div>

      <div class="home-body-box flex">
        <div class="left col">
          <div class="title">
            支持智能下单
          </div>
          <div class="comment">
            跨平台操作发货流程过于复杂？
          </div>
          <div class="content">
            分析客户特性选择合适的客户进行营销方案是CRM专员的一种必备技能当前可以通过客户属性、标签、订单交易三个维度寻找目标客户，进行精准二次营销。，通过分析销售漏斗的形状、阶段客户数以及各阶段的跟进转化率等对跟进情况进行评估和判断，量化预测可达到的销售成功率，并且在一定程度上反映跟进过程中存在的潜在问题，进而提出很多针对性的销售策略改进建议。帮助企业实现分析、考核成员联系客户情况的需求。
          </div>
        </div>
        <div class="right picBox col">
          <div class="top flex">
            <img class="sm" src="~@/assets/images/girl-eng.png" />
            <img class="sm" src="~@/assets/images/computer.png" />
          </div>
          <img class="md" src="~@/assets/images/computer-long.png" />
        </div>
      </div>

      <!-- <div class="tips">
        充值积分赠送相对应的VIP，等级越高做营销活动越优惠
      </div>
      <div class="title" style="margin-bottom: 57px">
        营销活动积分价格表
      </div> -->
      <!-- <div class="home-body-numGroup flex" style="margin-bottom: 120px">
        <div class="home-body-numGroup-list col">
          <div class="block actBlock">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="price">
            ￥1099
          </div>
        </div>

        <div class="home-body-numGroup-list col">
          <div class="block actBlock">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="price">
            ￥1099
          </div>
        </div>

        <div class="home-body-numGroup-list col">
          <div class="block actBlock">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="price">
            ￥1099
          </div>
        </div>

        <div class="home-body-numGroup-list col">
          <div class="block actBlock">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="block">
            Massa
          </div>
          <div class="price">
            ￥1099
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Amazon",
  methods: {
    application() {
      window.location = "http://acrm.ankeris.com";
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/common.styl"
.home
  &-header
    background-image url("../assets/images/bg-amazon.png")
</style>
