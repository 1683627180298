<template>
  <div class="home">
    <div class="home-header">
      <div class="home-header-box col">
        <div class="title">
          全流程私域流量运营平台
        </div>
        <div class="tips">
          整合线上线下渠道流量 客户智能精细化管理 <br />
          构建私域流量池
        </div>
        <div @click="$emit('contact')" class="btn">
          申请试用
        </div>
      </div>
    </div>
    <div class="home-body">
      <div class="tips">
        核心价值体系
      </div>
      <div class="title">
        构建品牌私域客户流量增长和管理体系
      </div>
      <div class="home-body-box flex">
        <div class="left col">
          <div class="tips">
            ABOUT US
          </div>
          <div class="title">
            全渠道引流
          </div>
          <div class="content">
            全域打通微信、天猫、京东、苏宁、线下门店等多渠道用户数据，构建品牌私域流量,识别品牌超级用户和潜在用户，精细化运营用户，促进品牌私域流量的持续增长，帮助平台全面了解客户，实现客户分层并制定针对性的策略持续激活客户。连接电商平台海量获客流量，打通主流电商、短视频和直播接口，抛弃传统获客门槛，快速触达存量用户，拓展现有用户基础。
          </div>
          <div class="operate isBtn">
            查看更多 >
          </div>
        </div>
        <div class="right col">
          <img src="~@/assets/images/fish.png" />
        </div>
      </div>

      <div class="home-body-box flex">
        <div class="right col">
          <img src="~@/assets/images/man.png" />
        </div>
        <div class="left col">
          <div class="tips">
            ABOUT US
          </div>
          <div class="title">
            个性化标签
          </div>
          <div class="content">
            用户智能标签，借助平台多维度运营构建客户指挥系统。识别品牌超级用户和潜在用户，精细化运营用户，促进品牌私域流量的持续增长，借助微信对私域流量用户持续种草，通过持续用户激励和高质量服务，刺激引导用户至电商平台复购，利用品牌用户画像，创建优质的客户体验分享和老带新模式，促进品牌规模传播效应带来的营销价值增长。
          </div>
          <div class="operate isBtn">
            查看更多 >
          </div>
        </div>
      </div>

      <div class="home-body-box flex">
        <div class="left col">
          <div class="tips">
            ABOUT US
          </div>
          <div class="title">
            培养忠诚用户 提升品牌复购
          </div>
          <div class="content">
            基于客户画像提供千人千面的产品推荐、活动优惠、返现激励，实现客户复购增购，交叉销售及老带新，为客户持续创造价值。精准定位品牌策略人群，挖掘高频消费场景和用户痛点。
          </div>
          <div class="operate isBtn">
            查看更多 >
          </div>
        </div>
        <div class="right col">
          <img class="bigImg" src="~@/assets/images/swim.png" />
        </div>
      </div>

      <div class="home-body-box flex">
        <div class="right col">
          <img class="bigImg" src="~@/assets/images/electron.png" />
        </div>
        <div class="left col">
          <div class="tips">
            ABOUT US
          </div>
          <div class="title">
            私域用户运营平台
          </div>
          <div class="content">
            对接阿里系和腾讯系电商平台流量汇成顶级流量海，开辟多重强势带货渠道，通过公众号、小程序、微信群、微信个人号等，打造爆款产品驱动流量，提升产品消费力，为品牌打造独一无二的营销增长，持续提升用户运营效率和客户满意度。
          </div>
          <div class="operate isBtn">
            查看更多 >
          </div>
        </div>
      </div>

      <div class="tips">
        行业落地解决方案
      </div>
      <div class="title">
        来自全球300万+客户的信任与选择
      </div>
      <div class="home-body-brand">
        <img src="@/assets/images/brand.png" />
      </div>

      <!-- <div class="home-body-cardGroup flex">
        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>

        <div class="home-body-cardGroup-card middle col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>

        <div class="home-body-cardGroup-card middle col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>

        <div class="home-body-cardGroup-card col">
          <div class="title flex">
            <svg-icon class="icon" svg-name="xing"></svg-icon>
            星火教育
          </div>
          <div class="text">
            Ancoris是社群运营中不可或缺的运营帮手，功能逻辑契合用户思维，可操作性强，能科学辅助社群常规维护和活动运营的顺利开展。
          </div>
        </div>
      </div> -->

      <div class="home-body-numGroup flex">
        <div class="home-body-numGroup-num col">
          <div class="title">
            24
          </div>
          <div class="tips">
            服务经验
          </div>
        </div>

        <div class="home-body-numGroup-num col">
          <div class="title">
            31
          </div>
          <div class="tips">
            入驻商家
          </div>
        </div>

        <div class="home-body-numGroup-num col">
          <div class="title">
            36
          </div>
          <div class="tips">
            有效获客
          </div>
        </div>

        <div class="home-body-numGroup-num col">
          <div class="title">
            18
          </div>
          <div class="tips">
            单日数据
          </div>
        </div>

        <div class="home-body-numGroup-num col">
          <div class="title">
            30
          </div>
          <div class="tips">
            成交率
          </div>
        </div>
      </div>
    </div>
    <div class="home-footer col">
      <div class="title">
        申请试用请联系我们
      </div>
      <div @click="$emit('contact')" class="btn">
        申请试用
      </div>
    </div>
  </div>
</template>

<script>
// import SvgIcon from "../components/SvgIcon.vue";
// @ is an alias to /src

export default {
  // components: { SvgIcon },
  name: "Home"
};
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/common.styl"
.home
  &-header
    background-image url("../assets/images/bg-home.png")
</style>
